import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Privacy() {
    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <h1 className="mb-4">Integritetspolicy</h1>
                    <p>Denna sida innehåller Laddvis integritetspolicy. Här beskriver vi hur vi samlar in, använder och skyddar dina personuppgifter.</p>
                    
                    <h2 className="mt-4">1. Insamling av information</h2>
                    <p>Vi samlar in information från dig när du registrerar dig på vår webbplats, loggar in på ditt konto, gör ett köp, deltar i en tävling, och/eller när du loggar ut. Den insamlade informationen inkluderar ditt namn, e-postadress, telefonnummer, och/eller kreditkort.</p>

                    <h2 className="mt-4">2. Användning av information</h2>
                    <p>All information vi samlar in från dig kan användas för att:</p>
                    <ul>
                        <li>Personifiera din upplevelse och bemöta dina individuella behov</li>
                        <li>Förbättra vår webbplats</li>
                        <li>Förbättra vår kundservice och ditt stödbehov</li>
                        <li>Kontakta dig via e-post</li>
                        <li>Administrera en tävling, kampanj eller undersökning</li>
                    </ul>

                    <h2 className="mt-4">3. Skydd av information</h2>
                    <p>Vi implementerar en mängd säkerhetsåtgärder för att bibehålla säkerheten för dina personliga uppgifter. Vi använder avancerad kryptering för att skydda känsliga uppgifter som överförs online. Endast anställda som ska utföra ett specifikt jobb (till exempel fakturering eller kundservice) får tillgång till personligt identifierbar information. De datorer/servrar som används för att lagra personligt identifierbar information lagras i en säker miljö.</p>

                    <p className="mt-4">För fullständig integritetspolicy, vänligen kontakta oss.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default Privacy;
